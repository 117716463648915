import React from "react"
import SEO from "@components/seo"
import * as data from "@data/parentDash"
import Layout from "@components/layout"
import AccountInfo from "@components/account_info"

const AccountInfoPage = () => {
  const { accountInfo } = data
  return (
    <Layout type="app">
      {process.env.UPDATE_ACCOUNT_INFO_PAGE === "true" && <AccountInfo />}
    </Layout>
  )
}

export default AccountInfoPage
