import React, { Component } from "react"
import "./style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import BackPage from "@components/_molecules/back_parent_dashboard"
import FormButton from "@components/_atoms/button_form_input_submit"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import * as data from "@data/parentDash"
import { format } from "path"
import * as errors from "@services/errors"
import InputParent from "@components/_molecules/input_parent_dash"
import InputPhone from "@components/_molecules/input_phone"
import { runInThisContext } from "vm"
import InputParentDash from "../_molecules/input_parent_dash"
import ResponseMessage from "@components/_atoms/response_message"

class AccountInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      emailErrorText: "",
      emailErrorStatus: "",
      phone: "",
      phoneErrorText: "",
      phoneErrorStatus: "",
      streetaddress: "",
      streetaddressErrorText: "",
      streetaddressErrorStatus: "",
      apt: "",
      aptErrorText: "",
      aptErrorStatus: "",
      city: "",
      cityErrorText: "",
      cityErrorStatus: "",
      state: "",
      stateErrorStatus: "",
      stateErrorText: "",
      zipcode: "",
      zipcodeErrorStatus: "",
      zipcodeErrorText: "",
      disabled: true,
      success: false,
      buttonSubmitted: true
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount = () => {
    if (process.env.UPDATE_ACCOUNT_INFO_PAGE !== "true") {
      if (typeof window !== `undefined`) window.location = `/app`
    } else {
      let _this = this,
        userInfoQuery = {
          query: `query{
        user {
          id
          email
          address1
          address2
          city
          phone
          postalCode
          province
        }
      }`
        }
      const options = {
        method: "POST",
        data: JSON.stringify(userInfoQuery),
        headers: headers.getHeaders(),
        url: headers.api
      }
      axios(options)
        .then(res => {
          if (res.data.errors) {
            _this.errorHandler(res.data.errors[0].message)
          } else {
            let { user } = res.data.data
            _this.setState({
              email: user.email,
              streetaddress: user.address1,
              apt: user.address2,
              city: user.city,
              state: user.province,
              zipcode: user.postalCode,
              phone: user.phone,
              disabled: false
            })
          }
        })
        .catch(error => {
          _this.errorHandler(error)
        })
    }
  }

  onPhoneChange(event) {
    this.setState({ phone: event.target.value, buttonSubmitted: false })
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      [`${event.target.name}ErrorStatus`]: false,
      generalError: null,
      buttonSubmitted: false
    })
  }
  handleSubmit = ev => {
    ev.preventDefault()
    let _this = this,
      phonenumber = this.state.phone.replace(/-/gi, ""),
      phoneCheck = /^1?[0-9]{10}$/,
      phoneErrorStatus = !phoneCheck.test(phonenumber)
    _this.setState({
      phoneErrorStatus
    })
  }

  errorHandler = err => {
    this.setState({
      generalError: errors.messaging(err)
    })
  }

  render() {
    const { accountInfo } = data
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0
    })

    return (
      <>
        <div className="heading-size-small">
          <BackPage data={accountInfo.heading} />
        </div>
        <div className="form-response">
          {this.state.success && (
            <ResponseMessage>
              Successfully Updated Account Information
            </ResponseMessage>
          )}
        </div>
        <div className="account-info">
          <form onSubmit={this.handleSubmit}>
            <div className="form-row">
              <InputParentDash
                data={{
                  name: `Email`,
                  type: `email`,
                  helpText: this.state.emailErrorText,
                  errorStatus: this.state.emailErrorStatus,
                  defaultVisibility: 0,
                  value: this.state.email,
                  isDisabled: this.state.disabled
                }}
                onChange={this.handleChange}
              />
              <InputPhone
                name="Phone number"
                value={this.state.phone}
                helpText={this.state.phoneErrorText}
                defaultVisibility={0}
                errorStatus={this.state.phoneErrorStatus}
                styleState={"parentDash"}
                isDisabled={this.state.disabled}
                onChange={this.onPhoneChange.bind(this)}
              />
            </div>
            <div className="form-row">
              <div className="field-sizing">
                <InputParentDash
                  data={{
                    name: `Street Address`,
                    value: this.state.streetaddress,
                    type: `text`,
                    helpText: this.state.addressErrorText,
                    errorStatus: this.state.addressErrorStatus,
                    isDisabled: this.state.disabled
                  }}
                  onChange={this.handleChange}
                />
                <InputParentDash
                  data={{
                    name: `Apt`,
                    value: this.state.apt,
                    type: `text`,
                    helpText: this.state.aptErrorText,
                    errorStatus: this.state.aptErrorStatus,
                    isDisabled: this.state.disabled
                  }}
                  onChange={this.handleChange}
                />
              </div>
              <InputParentDash
                data={{
                  name: `City`,
                  value: this.state.city,
                  type: `text`,
                  helpText: this.state.cityErrorText,
                  errorStatus: this.state.cityErrorStatus,
                  isDisabled: this.state.disabled
                }}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-row state-zip">
              <div className="field-sizing">
                <InputParentDash
                  data={{
                    name: `State`,
                    value: this.state.state,
                    type: `text`,
                    helpText: this.state.stateErrorText,
                    errorStatus: this.state.stateErrorStatus,
                    isDisabled: this.state.disabled
                  }}
                  onChange={this.handleChange}
                />
                <InputParentDash
                  data={{
                    name: `Zipcode`,
                    value: this.state.zipcode,
                    type: `text`,
                    helpText: this.state.zipcodeErrorText,
                    errorStatus: this.state.zipcodeErrorStatus,
                    isDisabled: this.state.disabled
                  }}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div style={{ width: `100%`, height: `3rem` }}></div>
            <FormButton
              text="Update Information"
              isDisabled={this.state.buttonSubmitted}
            />
          </form>
        </div>
      </>
    )
  }
}
export default AccountInfo
